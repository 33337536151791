import React from "react";
import StateDropdown from "../state-dropdown/StateDropdown";

const TitleCalculator = () => {
   return (
    <div className="*:mt-5">
      <div className="flex items-center mx-2">
        <h2 className="text-xl font-bold">TITLE INSURANCE RATES:</h2>
        <StateDropdown/>
      </div>
      <div className="flex items-center mx-2"> 
        <h3 className="text-[1.125rem] font-semibold">INSURED AMOUNT</h3>
        <input placeholder="100000" type="number" className=" p-2 border rounded-lg max-w-[100px] md:max-w-[200px] ml-auto"/>
      </div>
      
      <div className="bg-[#EFEFEF] py-2 p-2 rounded-lg text-[1.125rem] *:mt-3"> 
        <div className="flex items-center">
          <p>$0 up to $100,000 - $5.75 per $1000 (min $100)</p>
          <input placeholder="100000" type="number" inputmode="numeric"  className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px] "/>
          
        </div>
        <div className="flex items-center">
          <p>100,000 up to $1 Million - $5.00 per $1000</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
        <div className="flex items-center">
          <p>Over $1 Million up to $5 Million - $2.50 per $1000</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
        <div className="flex items-center">
          <p>Over $5 Million up to $10 Million - $2.25 per $1000</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
        <div className="flex items-center">
          <p>Over $10 Million - $2.00 per $1000</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
      </div>
      <div className="text-[1.125rem] mx-2">
        <p className="font-bold"> Simultaneous Policy:</p>
        <div className="flex items-center">
            <p className="max-w-[439px]">When both a Owner’s Policy (OP) and Loan Policy (LP) are to be issued.</p>
            <input placeholder="25" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
          </div>
      </div>
      <span className="bg-primary w-full h-[1px] inline-block"></span>

      <div className="flex text-[1.125rem] mx-2 items-center">
          <p className="font-bold">INSURED AMOUNT</p>
          <input placeholder="$ 600" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>

      
    </div>
  )
};

export default TitleCalculator;
