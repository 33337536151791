import React from "react";
import GetStarted from "../../components/GetStarted";
import ResourcesCard from "../../components/resources/ResourcesCard";
import { Link } from "react-router-dom";

const Resources = () => {
  const ResourcesCardData = [
    { text: "WIRE FRAUD" },
    { text: "TITLE AND CLOSING 101" },
    { text: "1031 EXCHANGE" },
    { text: "PRIVACY POLICY" },
    { text: "FIRST TIME HOMEBUYERS" },
    { text: "REAL ESTATE DICTIONARY" },
  ];

    return (
        <div className='relative overflow-hidden'>
            {/* Resources Banner */}
            <div className="lg:mt-20 mt-14 bg-[url('./assets/images/resources/resources_banner.png')] bg-top flex justify-center items-center bg-cover h-[370px]">
                <div className='text-5xl font-extrabold text-white text-center '>RESOURCES</div>
            </div>

      {/* background image */}
      <div className="bg-[url('./assets/images/background-images/background.png')]  bg-no-repeat bg-center">
        <div className="circle size-3 bg-primary absolute left-1/4 -ml-32 mt-20"></div>

        {/* tools to make your life */}
        <div className="py-24 flex flex-col justify-center items-center ">
          <div className="text-[26px] md:text-[28px] lg:text-[2rem] text-secondary font-bold text-center px-6">
            TOOLS TO MAKE YOUR{" "}
            <span className="text-primary">
              {" "}
              LIFE, AND TRANSACTION, A LITTLE EASIER
            </span>
          </div>
          <div className="text-[20px] md:text-[22px] lg:text-[1.625rem] text-secondary text-center mt-2 px-6">
            If you don’t see what you’re looking for please email one of our
            staff members.{" "}
          </div>
          <div className="circle size-8 bg-primary absolute right-6  -mt-[260px] z-0 max-sm:hidden"></div>

          {/* Resources cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 place-content-center py-14 max-sm:gap-0 gap-6 w-3/4 z-10">
            {ResourcesCardData.map((item, index) => (
              <ResourcesCard key={index} text={item.text} />
            ))}
          </div>

          {/* request a quote button */}
          <div className="circle size-20 bg-primary absolute left-12 mt-[380px] z-0 max-sm:hidden"></div>
          <div className="flex justify-center items-center w-full">
            <Link to="/services/request_a_quote">
              <button className="justify-center border-2 border-primary bg-white rounded-full text-[14px] md:text-[1.125rem] font-semibold text-primary hover:shadow-xl shadow-lg hover:shadow-[#00000029]  transition-all duration-300 ease-linear hover:bg-primary hover:text-white px-10  md:px-20 p-3">
                REQUEST A QUOTE
              </button>
            </Link>
          </div>
        </div>
      </div>

      <div>
        <GetStarted />
      </div>
    </div>
  );
};

export default Resources;
