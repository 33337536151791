import React from "react";
import ContactCards from "../../components/contact-us/ContactCards";
import GetStarted from "../../components/GetStarted";
import {
  Location,
  Mail,
  Phone,
} from "../../components/MySvgComponents/MySvgComponents";

const Contact = () => {
  const ContactCardsData = [
    { icon: <Phone />, text: "+1 (156) 252-5461" },
    { icon: <Mail />, text: "info@toplinenational.com" },
    {
      icon: <Location />,
      text: "1242 Berkshire Lane, Tarpon Springs, FL 34688",
      link: "https://maps.app.goo.gl/ADxUzEsEKX1Mqm3N6"
    },
  ];

  return (
    <div className="overflow-hidden ">
      {/* Top Banner */}
      <div className="bg-[url('./assets/images/contact/contact_banner.png')] bg-no-repeat bg-center bg-cover w-full flex justify-center items-center h-[400px]">
        <div className="text-[2.5rem] lg:text-[3rem] font-extrabold text-white text-center mt-20">
          CONTACT
        </div>
      </div>
      <div className="bg-[url('./assets/images/background-images/background.png')]  bg-no-repeat bg-center">
        <div className="relative md:py-20 pt-10">
          {/* Absolute Circles */}
          <div className="circle size-10 bg-primary absolute top-6 right-5 shadow-2xl hidden md:block"></div>
          <div className="circle size-36 bg-primary absolute top-12 -left-20 shadow-2xl hidden lg:block"></div>
          <div className="circle size-20 bg-primary absolute -bottom-20 left-60 shadow-2xl hidden xl:block"></div>

          <div className="flex flex-col lg:flex-row lg:w-3/4 lg:space-x-4 mx-auto px-4">
            {/* Map Section */}
            <div className="lg:w-1/2 flex mb-6 lg:mb-0">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3517.3865304624355!2d-82.6950397247289!3d28.165157975923112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2924d3b31f133%3A0x8e6b14646f7b217b!2s1242%20Berkshire%20Ln%2C%20Tarpon%20Springs%2C%20FL%2034688%2C%20USA!5e0!3m2!1sen!2sin!4v1726845717319!5m2!1sen!2sin"
                width="100%"
                height="100%"
                className="border-4 border-primary rounded-md"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>

            {/* Contact Information Section */}
            <div className="lg:w-1/2 p-4 flex flex-col space-y-4">
              <div className="font-bold text-[1.5rem] lg:text-[2rem] text-center">
                Contact <span className="text-primary">Information</span>
              </div>
              {ContactCardsData.map((item, index) => (
                <ContactCards key={index} icon={item.icon} text={item.text} link={item.link} />
              ))}
            </div>
          </div>
        </div>
        <GetStarted />
      </div>
    </div>
  );
};

export default Contact;
