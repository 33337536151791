import React from 'react';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-cover bg-center bg-[url('./assets/images/not-found/homeBanner.png')] " >
      <div className="bg-white bg-opacity-80 p-10 rounded-lg shadow-lg text-center">
        <h1 className="text-6xl font-bold text-gray-800 mb-4">404</h1>
        <p className="text-2xl text-gray-700 mb-8">Oops! The page you are looking for doesn't exist.</p>
        <a href="/" className="px-6 py-3 bg-primary text-white rounded-lg shadow-md hover:bg-secondary transition duration-300">
          Go Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;
