import React from 'react'

const ResourcesCard = ({text}) => {
  return (
    <div>
        <div className='bg-primary text-center rounded-lg mx-auto max-sm:mt-5 w-full max-w-[475px] h-fit p-5 text-white text-[14px] md:text-[1.125rem] hover:bg-white hover:text-primary border-2 border-primary transition-all font-medium duration-300 ease-linear hover:rounded-l-md hover:rounded-br-md hover:rounded-tr-[50px] hover:shadow-xl shadow-md shadow-[#00000029]'>
            {text}
        </div>

    </div>
  )
}

export default ResourcesCard