import { Bars3Icon } from "@heroicons/react/24/outline";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const menuRef = useRef(null); // Ref for the entire mobile menu
  const servicesRef = useRef(null); // Ref for the services dropdown

  // Handle menu toggle for mobile view
  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Handle services dropdown toggle
  const handleServicesToggle = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        servicesRef.current &&
        !servicesRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
        setIsServicesOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen, isServicesOpen]);

  // Close menu and dropdown when a link is clicked
  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsServicesOpen(false);
  };

  // Handle window resize to close mobile menu on large screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) { // Tailwind's `xl` breakpoint is 1024px
        setIsMenuOpen(false);
        setIsServicesOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className="bg-white xl:flex w-full fixed items-center xl:pl-20 xl:py-2 z-50 top-0 shadow-lg">
      <div className="flex items-center w-full xl:w-auto py-3">
        <a href="/">
          <img
            src="/images/navbar/topline_logo.png"
            alt="website_logo"
            className="h-auto lg:w-40 md:w-32 w-28 ml-10"
          />
        </a>
        <button
          type="button"
          className="flex xl:hidden ml-auto pr-5 md:pr-10 lg:pr-20"
          onClick={handleMenu}
        >
          <Bars3Icon className="size-8 lg:size-10" />
        </button>
      </div>

      <div
        className={`hidden xl:flex lg:ms-auto lg:items-center transition-all duration-300 ${
          isMenuOpen ? "block" : "hidden"
        }`}
        id="navbar-default"
      >
        <ul className="xl:flex space-x-0 xl:space-x-10 space-y-6 xl:space-y-0 pr-48">
          <li className="relative">
            <Link
              to="/"
              className="lg:text-xs hover:font-semibold hover:text-primary pb-1 flex justify-center transition-all duration-300"
              onClick={handleLinkClick} // Close menu on link click
            >
              HOME
            </Link>
          </li>
          <li className="relative">
            <Link
              to="/about"
              className="lg:text-xs  hover:font-semibold hover:text-primary pb-1 flex justify-center transition-all duration-300"
              onClick={handleLinkClick} // Close menu on link click
            >
              ABOUT US
            </Link>
          </li>
          <li className="relative">
            <button
              onClick={handleServicesToggle}
              className="lg:text-xs hover:font-semibold hover:text-primary pb-1 flex items-center justify-center transition-all duration-300"
            >
              SERVICES
              <img
                src="/images/navbar/arrow.png"
                alt="Arrow Icon"
                className="ml-2 w-2 h-auto"
              />
            </button>
            <div
              className={`absolute -left-[74px] top-7 transition-opacity duration-300 ease-in-out ${
                isServicesOpen ? "opacity-100" : "opacity-0 pointer-events-none"
              } bg-primary shadow-xl rounded-md w-48`}
            >
              <div className="size-5 bg-primary transform rotate-45 relative -top-1 left-[132px]"></div>

              <ul className="relative">
                <li className="-mt-4">
                  <Link
                    to="/services/our_offerings"
                    className="block ml-2 p-2 text-white transition-all duration-400 text-xs hover:font-semibold hover:tracking-wider"
                    onClick={handleLinkClick} // Close menu on link click
                  >
                    OUR OFFERINGS
                  </Link>
                </li>

                <li className="border-t border-gray-200 mx-2 ml-4"></li>

                <li>
                  <Link
                    to="/services/request_a_quote"
                    className="block ml-2 p-2 text-white transition-all duration-400 text-xs hover:font-semibold hover:tracking-wider"
                    onClick={handleLinkClick} // Close menu on link click
                  >
                    REQUEST A QUOTE
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          {/* <li className="relative">
            <Link
              to="/title_cost_estimator"
              className="lg:text-xs hover:font-semibold hover:text-primary pb-1 flex justify-center transition-all duration-300"
              onClick={handleLinkClick} // Close menu on link click
            >
              TITLE COST ESTIMATOR
            </Link>
          </li> */}
          <li className="relative">
            <Link
              to="/resources"
              className="lg:text-xs hover:font-semibold hover:text-primary pb-1 flex justify-center transition-all duration-300"
              onClick={handleLinkClick} // Close menu on link click
            >
              RESOURCES
            </Link>
          </li>
          <li className="relative flex items-center pr-3">
            <Link
              to="/contact"
              className="lg:text-xs hover:font-semibold hover:text-primary pb-1 flex justify-center pr-4 transition-all duration-300"
              onClick={handleLinkClick} // Close menu on link click
            >
              CONTACT
            </Link>

            {/* Search Icon and Box Wrapper */}
            <div className="absolute flex group -right-28 mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="size-6 group-hover:text-primary"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>

              <input
                type="text"
                placeholder="SEARCH"
                className="ml-2 w-0 group-hover:w-20 focus:w-20 bg-transparent placeholder-[#A1A1A1] text-sm text-gray-900 outline-none border-b border-[#A1A1A1] transition-all duration-500 ease-in-out"
              />
            </div>
          </li>
        </ul>
        <div></div>
      </div>

      {/* Mobile Menu Expanded */}
      <div
        className={`w-full transition-all duration-300 shadow-lg ${
          isMenuOpen ? "block" : "hidden"
        }`}
        id="navbar-default"
      >
        <ul className="space-y-4 py-4 px-5 text-center">
          <li>
            <Link 
              to="/"
              className="text-sm font-bold hover:text-primary"
              onClick={handleLinkClick} // Close menu on link click
            >
              HOME
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="text-sm font-bold hover:text-primary"
              onClick={handleLinkClick} // Close menu on link click
            >
              ABOUT US
            </Link>
          </li>
          <li>
            <button
              onClick={handleServicesToggle}
              className="text-sm font-bold hover:text-primary flex items-center justify-center mx-auto"
            >
              SERVICES
              <img
                src="/images/navbar/arrow.png"
                alt="Arrow Icon"
                className="ml-2 w-2 h-auto"
              />
            </button>
            {isServicesOpen && (
              <ul ref={servicesRef} className="mt-2 space-y-2">
                <li>
                  <Link
                    to="/services/our_offerings"
                    className="text-sm font-semibold hover:text-primary"
                    onClick={handleLinkClick} // Close menu on link click
                  >
                    OUR OFFERINGS
                  </Link>
                </li>
                <li>
                  <Link
                    to="/services/request_a_quote"
                    className="text-sm font-semibold hover:text-primary"
                    onClick={handleLinkClick} // Close menu on link click
                  >
                    REQUEST A QUOTE
                  </Link>
                </li>
              </ul>
            )}
          </li>
          {/* <li>
            <Link
              to="/title_cost_estimator"
              className="text-sm font-bold hover:text-primary transition-all duration-300"
              onClick={handleLinkClick} // Close menu on link click
            >
              TITLE COST ESTIMATOR
            </Link>
          </li> */}
          <li>
            <Link
              to="/resources"
              className="text-sm font-bold hover:text-primary"
              onClick={handleLinkClick} // Close menu on link click
            >
              RESOURCES
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="text-sm font-bold hover:text-primary"
              onClick={handleLinkClick} // Close menu on link click
            >
              CONTACT
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;