import React from "react";

const BannerMenuCard = ({ num, text }) => {
  return (
    <>
      <div className=" group w-[18rem] h-[6.25rem] ">
        <div className=" text-4xl group-hover:text-3xl flex flex-col w-full relative ">
          <div className="w-1/2 group-hover:translate-x-[132px] transition-all duration-500 ease-in-out group-hover:text-[#E05555] font-thin group-hover:font-light mb-1">
            {num}
          </div>
        </div>

        <div className="relative inline-block group w-[18rem]">
          <span className="relative z-10 group-hover:bg-transparent transition-all duration-500 ease-in-out flex flex-col items-center m-3 text-xl group-hover:text-2xl group-hover:font-semibold group-hover:text-[#E05555]">
            {text}
          </span>

          <div className="border-t-4 border-primary rounded-t-lg absolute bottom-0 left-0 right-0 h-0.5 bg-white group-hover:h-full group-hover:w-full group-hover:border-[3px] group-hover:border-primary transition-all duration-500 ease-in-out"></div>
        </div>
      </div>
    </>
  );
};

export default BannerMenuCard;
