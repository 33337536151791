import React from "react";

const policy = [
  {
    heading: "Purpose",
    desc: [
      `This Policy sets out how we at Topline National Title process the personal data of individuals (each a “user”, “you”, “your”) who contact us or who use our websites,
      services, programs, content and related features (collectively, the “Services”). It sets out our views and practices regarding your personal data and how we will
      treat it. We will let you know, by posting on our website or otherwise, if we make any changes to this policy from time to time. Your continued use of the Services
      after notifying such changes will amount to your acknowledgement of the amended policy`,
    ],
  },
  {
    heading: "Scope",
    desc: [
      `This policy applies to all individuals who access, use or control Topline National Title owned resources. This includes but is not limited to
      Topline National Title employees, contractors, consultants and other workers including all personnel affiliated to external organizations with access to the Topline National Title network. The term
      employee(s), its synonyms (staff, personnel etc.) and vice versa hereafter encompasses the scope defined in the paragraph above.`,
    ],
  },
  {
    heading: "Policy",
    subheading: "Personal data",
    desc: [
      `“Personal data” means any information relating to an identified or identifiable natural person, known as ‘data subject’, who can be
      identified directly or indirectly; it may include name, address, email address, phone number, IP address, location data, cookies, call recording and similar information. It
      may also include “special categories of personal data” such as racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership and
      the processing of genetic data, biometric data for the purpose of uniquely identifying a data subject, data concerning health or data concerning a natural person’s sexual orientation.`,
    ],
  },
  {
    subheading: "How do we use your personal data?",
    desc: [`We may process the following personal data:`],
    list: [
      `Information provided by you. You may give us information about you by filling in forms such as the contact form on our website or any Content Download Form,
      making applications in respect of job postings, corresponding with us by e-mail, phone or otherwise. This information may include your name, mailing address
      (including zip code) email address, employer, job title and department, telephone number and facsimile numbers, the content of any email you send to us and other personal
      identifying information.`,

      `Information about others. You may also provide to us personal data relating to third parties, such as people who you work with, reporters and other individuals who comment
      on markets and news, business actors relevant to your industry and other individuals.`,

      `Information about your device. With regard to each of your visits to our website, we may collect technical information about your device. This information
      is compiled and analyzed on both a personal and an aggregated basis. This information may include the Web site’s Uniform Resource Locator (“URL”) that you
      just came from, which URL you go to next, what browser you are using, and your Internet Protocol (“IP”) address. A URL is the global address of documents
      and other resources on the World Wide Web. An IP address is an identifier for a computer or device on a Transmission Control Protocol / Internet Protocol
      (“TCP/IP”) network, such as the World Wide Web. Networks like the World Wide Web use the TCP/IP protocol to route information based on the IP address of
      the destination. In other words, an IP address is a number that is automatically assigned to your computer whenever you are surfing the web, allowing web
      servers to locate and identity your computer. Computers require IP addresses in order for users to communicate on the Internet.`,
      `Information from third party sources. This may include information received from our service providers and other third parties providing us with your
      information which is publicly available or collected by them.`,
    ],
  },
  {
    subheading: "What personal data do we process?",
    desc: [
      `We will only process personal data, in accordance with applicable law, for the following purposes`,
    ],
    list: [
      `Responding to your queries, requests and other communications.`,

      `Providing the Services including our website and related features as well as providing other information services.`,

      `Resolve disputes, troubleshoot problems and enforce our agreements with you, including our Site Terms of Use, Sales Terms and Conditions and this Private Policy.`,

      `Enabling suppliers and service providers to carry out certain functions on our behalf in order to provide the Services such as
      sending postal mail and e-mail, removing repetitive information from customer lists, analyzing data, providing marketing assistance, etc.
      These third party service providers have access to personal data needed to perform their functions, but may not use it for other purposes.
      We do not sell, rent, share, or otherwise disclose personal data from customers for commercial purposes.`,

      `Participate in discussion forums.`,

      `Diagnose problems with our servers, software, to administer our site, to gather demographic information and to track visitors to our website.`,

      `Sending you personalized marketing communications based on your interests opted upon filling forms on our website.`,

      `Serving personalized advertising to your devices, based on your interests in accordance with our Cookie Statement. You have the right to disable cookies as set out in Cookie statement.`,

      `Complying with applicable law, including in response to a lawful request from a court or regulatory body.`,
    ],
  },
  {
    subheading: "Disclosure of personal data",
    desc: [
      `There are circumstances where we may wish to disclose or are compelled to disclose your personal data to third parties. These scenarios include disclosure to:`,
    ],
    list: [
      `Our affiliates and subsidiaries.`,

      `Our service providers who capture and store data collected through the forms that are filled by visitors to our website.`,

      `Subject to appropriate legal basis such as consent, our advertising and marketing teams who enable us, for example, to deliver personalized ads to your devices or who may contact you by email, telephone, SMS or by other means`,

      `Our service partners to operate our website and better serve the visitors to our website`,

      `Payer, such as your employer, who pays for our products and services.`,

      `Public authorities where we are required by law to do so and`,

      `Other third parties where you have provided your consent.`,
    ],
    desc2: [
      "The service providers, if ever engaged, are contractually bound not to share Personal data collected from visitors to our website with anyone else.",
    ],
  },

  {
    heading: "Cookies and Similar Technologies",
    desc: [
      `This site uses cookies and similar technologies to track particular aspects of you and other people who visit us. This tracking is done to provide us with information on
        how people move about the site, what is of interest to them and what is not, how our marketing is performing, and incidental items such as what percentage of users access
        the site from a personal computer versus a mobile phone. You may block most cookies by adjusting your browser settings as well as responding to the cookie consent notice
        that appears when you visit this site.`,
    ],
  },
  {
    heading: "Data Security",
    desc: [
      `We take due care to protect customer data. Technical measures are in place to prevent unauthorized or unlawful access to data and against accidental loss, destruction,
        or damage to data. The employees who are dealing with the data have been trained to protect the data from any illegal or unauthorized usage.`,

      `We work to protect the security of your information during transmission by using Secure Sockets Locker (SSL) software, which encrypts information you input. SSL allows sensitive information to be transmitted securely.`,

      `We maintain physical, electronic and procedural safeguards in connection with the collection, storage and disclosure of personal customer information. We take reasonable steps to help protect your personal information in an effort to prevent the loss, misuse, unauthorized access, disclosure, alteration and destruction of such information. It is your responsibility to protect your usernames and passwords to help prevent anyone from accessing or abusing your accounts and services. You should not use or reuse the same passwords you use with other accounts as your password for our services. It is important for you to protect against unauthorized access to your password and your computers, devices and applications. Be sure to sign off when you finish using a shared computer.`,

      `Information you provide to us is shared on our secure servers. We have implemented appropriate physical, technical and organizational measures designed to secure your information against accidental loss and unauthorized access, use, alteration, or disclosure. In addition, we limit access to personal data to those employees, agents, contractors and other third parties that have a legitimate business need for such access. Information collected from you will be stored for such period as required to complete the transaction entered into with you or such period as mandated under the applicable laws.`,
    ],
  },
  {
    heading: "Links to Third Party Site/Apps",
    desc: [
      `Our Site may, from time to time, contain links to and from other websites of third parties. Please note that if you follow a link to any of these websites, such websites will apply different terms to the collection and privacy of your personal data, and we do not accept any responsibility or liability for these policies. When you leave our Site, we encourage you to read the privacy policy of every website you visit`,
    ],
  },
  {
    heading: "Newsletter",
    desc: [
      `Following subscription to Topline National Title’s newsletter, your e-mail address is used for our advertising purposes until you cancel the newsletter again. Cancellation is possible at any time. The following consent has been expressly granted by you separately, or possibly in the course of an ordering process: (I am accepting to receive newsletter from this website). You may revoke your consent at any time with future effect. If you no longer want to receive the newsletter, then unsubscribe by clicking on unsubscribe option given in the email footer.`,
    ],
  },
  {
    heading: "Changes to This Policy",
    desc: [
      `We may change this policy from time to time. If we make any changes to this policy, we will change the "Last Updated" date at the end of this policy to appropriately reflect the change. You agree that your continued use of our services after such changes have been published to our services will constitute your acceptance of such revised policy.`,
    ],
  },
  {
    heading: "Enforcement",
    desc: [
      `Topline National expects all employees to comply with this policy and any related policy, standards, processes, procedures and guidelines. Failure and/or refusal to abide by this policy may be deemed as violations. Compliance with the policies will be a matter for periodic review by the Information security officer / Information Security Team. Any employee found to have violated this policy may be subject to disciplinary action, as deemed appropriate by the policies of management and Human Resources.`,
    ],
    list: [
      `Monitoring: the company employs appropriate technology solutions to monitor policy/ procedure compliance.`,
      `Self-Assessment: Managers and Department Heads are required to conduct self-assessment within their areas of control to verify compliance to this policy/ procedure.`,
      `Security Audits: Internal Audit may assess the implementation of and compliance with this policy/ procedure as part of its audit program.`,
    ],
  },
  {
    heading: "Special Circumstances and Exceptions",
    desc: [
      `All exceptions to this policy/ procedure will require a waiver explicitly approved by Topline National’s Chief Information Security Officer.`,
    ],
  },
  {
    heading: "Contact",
    desc: [
      "For any concerns, questions or grievances about privacy, please contact us with a thorough description and we will try to resolve the issue for you.",
    ],
  },
  {
    subheading: "Contact Details",
    desc: ["Phone: +1 (156) 252-5461", "Email: info@toplinenational.com"],
  },
  {
    desc: [
      "Address :",

      "1242 Berkshire Lane,",

      "Tarpon Springs,",

      "FL 34688",
    ],
  },
];

const TermsAndConditions = () => (
  <div className="p-5">
    <div className="text-center sm:text-xl md:text-2xl lg:text-3xl lg:mt-36 max-lg:mt-24 max-md:mx-2 font-extrabold text-primary">
      TopLine Privacy Policy (Last Updated September 03, 2024)
    </div>
    <div className="max-sm:mx-4 md:mx-6 lg:mx-20 mt-10">
      {policy?.map((section, index) => (
        <div key={index} className="mb-5">
          {section.heading && (
            <div className="sm:text-xl md:text-2xl font-bold mb-1">
              {section.heading}
            </div>
          )}
          {section.subheading && (
            <div className="text-lg md:text-xl font-medium">
              {section.subheading}
            </div>
          )}
          {section.desc?.map((paragraph, i) => (
            <p key={i} className="text-sm md:text-base xl:text-lg">
              {paragraph}
            </p>
          ))}
          {section.list && (
            <ul className="list-disc lg:ml-14 sm:ml-10 ml-5 pl-5 mt-2 text-base max-md:text-sm">
              {section.list?.map((item, i) => {
                const [textBeforeColon, textAfterColon] = item.split(":");
                return (
                  <li key={i}>
                    {textAfterColon ? (
                      <>
                        <span className="underline">
                          {textBeforeColon.trim()}
                        </span>
                        : {textAfterColon.trim()}
                      </>
                    ) : (
                      item
                    )}
                  </li>
                );
              })}
            </ul>
          )}
          {section.desc2 &&
            section.desc2?.map((paragraph, i) => (
              <p key={i} className="text-sm md:text-base">
                {paragraph}
              </p>
            ))}
        </div>
      ))}
    </div>
  </div>
);

export default TermsAndConditions;
