import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation } from 'swiper/modules';
import "swiper/swiper-bundle.css"

    const testimonials = [
        {
          logo: 'images/about-us/partners-logos/earnnest-wordmark.png',
          alt: 'Earnest Logo',
          text: 'As clients demand faster turn times, more transparency and virtual everything, the paymints.io platform solves for all issues related to paper checks and wire fraud. Paymints.io allows for completing a digital mortgage with a virtual closing with a secure digital funds transfer rather than having to mail a paper check or go to the bank to request a wire.',
        },
        {
          logo: 'images/about-us/partners-logos/norarize.svg',
          alt: 'Notarize Logo',
          text: 'As clients demand faster turn times, more transparency and virtual everything, the paymints.io platform solves for all issues related to paper checks and wire fraud. Paymints.io allows for completing a digital mortgage with a virtual closing with a secure digital funds transfer rather than having to mail a paper check or go to the bank to request a wire.',
        },
        {
            logo:'images/about-us/partners-logos/qualia.svg', 
            alt:'Qualia Logo',
            text: 'As clients demand faster turn times, more transparency and virtual everything, the paymints.io platform solves for all issues related to paper checks and wire fraud. Paymints.io allows for completing a digital mortgage with a virtual closing with a secure digital funds transfer rather than having to mail a paper check or go to the bank to request a wire.',
        },
        {
            logo:'images/about-us/partners-logos/certifid.svg', 
            alt:'Certifid Logo',
            text: 'As clients demand faster turn times, more transparency and virtual everything, the paymints.io platform solves for all issues related to paper checks and wire fraud. Paymints.io allows for completing a digital mortgage with a virtual closing with a secure digital funds transfer rather than having to mail a paper check or go to the bank to request a wire.',
        },
        {
            logo:'images/about-us/partners-logos/we_run_on_eos.png', 
            alt:'We Run On EOS Logo',
            text: 'As clients demand faster turn times, more transparency and virtual everything, the paymints.io platform solves for all issues related to paper checks and wire fraud. Paymints.io allows for completing a digital mortgage with a virtual closing with a secure digital funds transfer rather than having to mail a paper check or go to the bank to request a wire.',
        },
        {
            logo:'images/about-us/partners-logos/secure_insight.png', 
            alt:'Secure Insight Logo',
            text: 'As clients demand faster turn times, more transparency and virtual everything, the paymints.io platform solves for all issues related to paper checks and wire fraud. Paymints.io allows for completing a digital mortgage with a virtual closing with a secure digital funds transfer rather than having to mail a paper check or go to the bank to request a wire.',
        }
                
      ];

      export const Testimonials = () => {
      return (

        <div>
      
        
        <Swiper
            modules={[Navigation]}
            spaceBetween={0}
            slidesPerView={2}
            navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              className=''
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                1270: {
                  slidesPerView: 2,
                },
              }}
        >   
            {testimonials?.map((testimonial, index) => (
                
            <SwiperSlide key={index}>
                <div className='p-5'>
                    <div className='bg-white shadow-[#00000029]  p-10 shadow-lg rounded-lg text-left h-[390px] lg:h-[359px] max-w-[488px]'>
                        <img src={testimonial.logo} alt={testimonial.alt} className='max-h-[33px]' />
                        <div className='text-sm md:text-[1rem] text-secondary mt-14 leading-[25px]'>{testimonial.text}</div>
                    </div>
                </div>
            </SwiperSlide>
            
            ))}
            <div className='flex justify-center md:justify-end '>
                <div className='relative z-10 p-10 space-x-5 w-fit right-0 md:right-5'>
                    <div className=''>
                        <img src='images\button-icons\arrow_right_bold.svg' alt='pervious button' className='swiper-button-prev scale-[-1]'/>
                    </div>  
                    <div className=''>
                        <img src='images\button-icons\arrow_right_bold.svg' alt='next button' className='swiper-button-next' />
                    </div>
                </div>
            </div>
            {/* <div className=''>
                <img src='images\button-icons\arrow_right_bold.svg' alt='pervious button' className='swiper-button-prev scale-[-1] !top-full !left-3/4'/>
            </div>
            <div className=''>
                <img src='images\button-icons\arrow_right_bold.svg' alt='next button' className='swiper-button-next !top-full' />
            </div> */}
            
            
        </Swiper>
        
        </div>
        );
    }


