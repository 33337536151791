import React from 'react'
import TitleCostEstimatorComponent from '../../components/title-cost-estimator/TitleCostEstimatorComponent'
import GetStarted from '../../components/GetStarted'

const TitleCostEstimator = () => {
  return (
    <div className='relative overflow-hidden'>
        {/* Title Cost Estimator Banner*/}
        <div className="bg-[url('./assets/images/title-cost-estimator/title_cost_estimator_banner.png')] overflow-hidden bg-no-repeat bg-top  bg-fixed  w-full flex flex-col  justify-center items-center h-[496px] -z-10 ">
            <div className='text-5xl font-extrabold text-white text-center '>TITLE COST ESTIMATOR</div>
            <div className='text-[1.25rem] max-w-[945px] mt-7  text-white text-center'> Lorem ipsum dolor sit amet, consetetur sadipscing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam 
                voluptua. At vero eos et accusam et justo duo dolores </div>
        </div>
        <div className="bg-[url('./assets/images/background-images/background.png')]  bg-no-repeat bg-center">
        <div>
          <TitleCostEstimatorComponent/>
        </div>
        <div className="circle absolute size-56 bg-primary -left-32 -mt-20"></div>
        <div>
          <GetStarted />
        </div>
        </div>


    </div>
  )
}

export default TitleCostEstimator