// import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../components/Navbar';
import NotFound from '../view/NotFound';
import AboutUs from '../view/about-us/AboutUs';
import ContactUs from '../view/contact-us/ContactUs';
import Homepage from '../view/home/Homepage';
import Footer from '../components/Footer';
import RequestAQuote from '../view/services/RequestAQuote';
import OurOfferings from '../view/services/OurOfferings';
// import DifferentiatorMenuCard from '../components/services/DifferentiatorMenuCard';
import Resources from '../view/resources/Resources';
import TitleCostEstimator from '../view/title-cost-estimator/TitleCostEstimator';
import ScrollToTop from '../components/ScrollToTop';
import TermsAndConditions from '../view/privacy-policy/TermsAndConditions';

// const Home = lazy(() => import('../pages/Home'));
// const About = lazy(() => import('../pages/About'));
// const Contact = lazy(() => import('../pages/Contact'));

const AppRoutes = () => {
  
  return (
    <Router>
      <ScrollToTop/>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Homepage/>} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/services/our_offerings" element={<OurOfferings />} />
        <Route path="/services/request_a_quote" element={<RequestAQuote/>} />
        {/* <Route path="/payment" element={<Payment/>} /> */}
        <Route path="/title_cost_estimator" element={<TitleCostEstimator />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/privacy-policy" element={<TermsAndConditions/>} />
        <Route path="*" element={<NotFound />} />

      </Routes>
      <Footer/>
    </Router>
  );
};

export default AppRoutes;
