import React from "react";
import StateDropdown from "../state-dropdown/StateDropdown";

const SellerCostEstimator = () => {
  return (
    <div className="*:mt-5 ">
    <div className="flex items-center mx-2">
      <h2 className="text-xl font-bold">SELLER COST ESTIMATOR</h2>
      <StateDropdown/>
      
    </div>
    <div className="flex text-[1.125rem] mx-2 items-center">
        <p className="font-normal">Sales Price</p>
        <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
    
    
    <div className="bg-[#EFEFEF] text-[#3A3A3A] py-2 p-2 rounded-lg text-[1.125rem] *:mt-3"> 
      <div className="flex items-center">
        <p>Search Fees</p>
        <input placeholder="$225" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
      <div className="flex items-center">
        <p>Settlement Fee</p>
        <input placeholder="$400" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
      <div className="flex items-center">
        <p>Title Premium</p>
        <input placeholder="$575.00" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
      <div className="flex items-center">
        <p>Deed Document Stamps</p>
        <input placeholder="$700.00" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
      <div className="flex items-center">
        <p>Estimated Total Cost</p>
        <input placeholder="$1,900" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
    </div>
    <p className=" text-sm mx-2  text-[#3A3A3A]">This does not include any realtor fees, property taxes, HOA fees or mortgage payoffs</p>


    
  </div>
  )
};

export default SellerCostEstimator;
