import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DifferentiatorMenuCard from "../../components/services/DifferentiatorMenuCard";
import OurServiceCard from "../../components/services/OurServiceCard";
import { MachineLearning, Medal, Practice, Time } from "../../components/MySvgComponents/MySvgComponents";
import { Link } from "react-router-dom";

const OurOfferings = () => {
  const DifferentiatorMenuCardData = [
    { icon: <MachineLearning/>, title: "Training" },
    { icon: <Practice/>, title: "Dedicated Project Manager For Client" },
    { icon: <Medal/>, title: "Independent Quality Team" },
    { icon: <Time/>, title: "Faster Turnaround Time" },
  ];

  const OurServiceCardData = [
    {
      title: "Title Search & Examination",
      desc: "We have a Nationwide coverage through our Online and Vendor Network, our team of examiners have a thorough understanding on state Statues & examining guidelines to deliver best in class verified searches.",
    },
    {
      title: "Settlement Services",
      desc: "We understand the criticality of the files when it comes to closing desk, quicker turnaround time and precision in getting tasks completed correctly eases lot of last-minute stress at the closing table.",
    },
    {
      title: "Title Report Indexing & Data Extraction",
      desc: "We have a Nationwide coverage through our Online and Vendor Network, our team of examiners have a thorough understanding on state Statues & examining guidelines to deliver best in class verified searches.",
    },
    {
      title: "Faster turnaround time",
      desc: "We understand the criticality of the files when it comes to closing desk, quicker turnaround time and precision in getting tasks completed correctly eases lot of last-minute stress at the closing table.",
    },
    
  ];

  const location = useLocation();

  // Scroll to the section when the hash in the URL changes
  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <div className="overflow-hidden mx-auto bg-[url('./assets/images/bg_image_all.png')] bg-center bg-cover">
      {/* Top Banner */}
      <div className="bg-[url('./assets/images/services/our-offerings/banner.png')] bg-no-repeat bg-center bg-cover w-full flex justify-center items-center h-[530px]">
        <div className="text-[3rem] max-md:text-3xl font-extrabold text-white text-center mt-20 mx-2">
          Full Range of Residential and Commercial
          <div className="text-primary mt-2"> Real Estate Services </div>
          <div className="mt-5 font-normal text-center text-3xl xl:px-48 px-10 max-md:px-2 max-md:text-[18px]">
            {" "}
            Full Service Title And Escrow Company Providing Excellent Service
            And Continually Working To Understand Our Customers’ Needs.{" "}
          </div>
        </div>
      </div>

      <div className="relative h-full  py-24 lg:px-40 md:px-20 sm:px-10 px-5 mb-10">
        <div className="circle size-4 bg-primary absolute top-36 left-96 right-10 hidden lg:block"></div>
        <div className="circle size-36 bg-primary absolute top-12 -right-20 shadow-2xl hidden md:block"></div>
        <div id="our-services" className="circle size-10 bg-primary absolute bottom-0 left-10 shadow-2xl"></div>

        <h2 className="text-[2rem] max-sm:text-2xl font-bold text-center">
          HOW TOPLINE NATIONAL TITLE
          <span className="text-primary"> WORKS</span>
        </h2>

        <p className="mt-10 text-lg max-sm:text-base text-center">
          TOPLINE NATIONAL TITLE provides a full range of title production
          services to agents, agencies, and title companies. Based on the
          requirements specific to the buying/selling/re-financing criteria, our
          experts ensure that the detailed reports are sourced from credible
          government records.
        </p>

        <p className="mt-4 text-lg max-sm:text-base text-center">
          Having understood the objectives of title production, TOPLINE NATIONAL
          TITLE delivers the most comprehensive title search reports that
          include verified ownership data, voluntary/involuntary monetary liens,
          property encumbrances, tax records, and much more. To discuss your
          requirements and for a custom quote, contact us today.
        </p>
      </div>

      {/* OUR SERVICES */}

      <div className="flex flex-wrap xl:flex-nowrap xl:px-20 lg:px-16 px-8 justify-center items-center">
        <div className="flex lg:flex-col flex-col xl:mb-20 justify-center xl:mr-8">
          <div className="font-bold text-3xl max-sm:text-2xl max-sm:text-center">
            OUR<span className="text-primary"> SERVICES</span>
          </div>
          <p className="xl:w-[470px] w-full text-[#A1A1A1] text-[18px] max-sm:text-base mt-6">
            TOPLINE NATIONAL TITLE has a thorough understanding of title
            insurance market & are well versed with its requirements. Our team
            of 600+ certified examiners understand state statues and
            underwriting guidelines, thus delivering a quality product. We offer
            nationwide coverage through our online coverage and ground network
            of field abstractors. Get your reports delivered in 8-12 hours
            (online)!!
          </p>
          <div className="w-full flex xl:justify-start justify-center mt-4 mb-10">
          <Link to="/services/request_a_quote">
          <button className="justify-center border-2 border-primary bg-white rounded-full text-[1.125rem] max-sm:text-[15px] font-semibold text-primary shadow-lg hover:shadow-[#00000029]  transition-all duration-200 ease-linear hover:bg-primary hover:text-white xl:px-14 px-10 p-2 mt-6">
              REQUEST A QUOTE
            </button>
            </Link>
            
          </div>
        </div>

        <div className="container mx-auto">
          <div className="md:grid flex-wrap gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-2">
            {OurServiceCardData.map((item, index) => (
              <OurServiceCard key={index} title={item.title} desc={item.desc} />
            ))}
          </div>
        </div>
      </div>

      <div className="relative h-full  mt-40 max-md:mt-10">
        <div className="circle size-52 bg-primary absolute -top-32 -left-28 shadow-2xl hidden md:block"></div>
        <div className="circle size-28 bg-primary absolute -top-12 right-5 shadow-2xl hidden md:block"></div>
        <div className="circle size-10 bg-primary absolute -top-14 right-32 shadow-2xl hidden md:block"></div>

        <div className="text-3xl font-bold text-center py-12 max-sm:py-6 max-sm:text-2xl">
          KEY <span className="text-primary"> DIFFERENTIATORS </span>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          {DifferentiatorMenuCardData.map((item, index)=>(
            <DifferentiatorMenuCard key={index} icon={item.icon} title={item.title}/>
          ))}
          
        </div>

        <div className="w-full flex justify-center py-20 max-md:py-14">
        <Link to="/services/request_a_quote">
        <button className="justify-center border-2 border-primary bg-white rounded-full text-[1.125rem] font-semibold text-primary hover:shadow-xl hover:shadow-[#00000029]  transition-all duration-300 ease-linear hover:bg-primary hover:text-white xl:px-20 px-14 p-3">
            REQUEST A QUOTE
          </button>
            </Link>
          
        </div>
      </div>
    </div>
  );
};

export default OurOfferings;
