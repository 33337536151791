import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';

const CustomDropdown = ({ options }) => {
  const [selected, setSelected] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Create a ref for the dropdown container

  const handleSelect = (value) => {
    setSelected(value);
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener for clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="ml-auto relative w-full max-w-[100px] md:max-w-[200px] cursor-pointer">
      <div
        className={`flex items-center text-black p-2  rounded-md border ${selected ? 'bg-primary text-white' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {selected ? options.find(option => option.value === selected).label : 'Select State'}
        <ChevronDownIcon className={`w-6 h-6 text-black ml-auto ${selected ? 'text-white' : ''}`} />
      </div>
      
      {isOpen && (
        <div className="absolute top-full left-0 right-0 mt-1 bg-[#3A3A3A] rounded-md shadow-lg z-10 ">
          {options.map(option => (
            <div
              key={option.value}
              className={`mt-2 mx-4 rounded-lg transition-colors duration-200 ease-linear  ${option.disabled ? 'text-[#3A3A3A] ' : 'text-white hover:text-primary'} ${selected === option.value ? '' : ''}`}
              onClick={() => !option.disabled && handleSelect(option.value)}
            >
              {option.label}
              <span className='w-full h-[1px] bg-white block bg-opacity-35 mt-1'></span>
            </div>
            
          ))}
          
        </div>
      )}
    </div>
  );
};

const defaultOptions = [  
  { value: 'FLORIDA', label: 'FLORIDA' },
  { value: 'INDIANA', label: 'INDIANA' },
  { value: 'COLORADO', label: 'COLORADO' },
];

const DefaultCustomDropdown = () => <CustomDropdown options={defaultOptions} />;

export default DefaultCustomDropdown;
