import React from "react";
import BannerMenuCard from "../../components/home/BannerMenuCard";
// import Navbar from "../../components/Navbar";
// import Footer from "../../components/Footer";
import GetStarted from "../../components/GetStarted";
//bg-[url('./assets/images/bg_image_all.png')]
const Homepage = () => {
  const items = [
    { num: "01", text: "SELLER NET SHEET" },
    { num: "02", text: "PAY EARNEST MONEY" },
    { num: "03", text: "ORDER" },
    { num: "04", text: "RATE CALCULATOR" },
  ];

  return (
    <>
      <div className="relative">
        {/* Banner Image */}
        <div className="relative h-full mt-20">
          <div className="fixed inset-0 -z-10 mx-auto !max-w-[1920px] !max-h-[1080px] ">
            <img
              src="/images/homepage/homeBanner.png"
              alt="banner_image"
              className="object-cover object-center w-full h-full"
            />
          </div>

          <div className="relative z-10 text-white h-screen p-6 sm:p-24 md:p-20 lg:py-24 lg:px-48 xl:py-24 xl:px-4  flex flex-col items-center !max-w-[1920px] mx-auto !max-h-[1080px] ">
            <div className="h-[10%] sm:h-[10%] lg:h-[30%] xl:h-[40%] flex items-center justify-center">
              <img
                src="/images/homepage/logoBanner.png"
                alt=""
                className="h-28 sm:h-36 md:h-40 lg:h-40 xl:h-48 mt-12"
              />
            </div>

            {/* <div className="mt-10 sm:mt-20 md:mt-28 lg:mt-36 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 justify-items-center">
              {items.map((item, index) => (
                <BannerMenuCard key={index} num={item.num} text={item.text} />
              ))}
            </div> */}

            {/* <div className="mt-10 sm:mt-16 lg:mt-28 xl:mt-36 flex flex-wrap justify-center gap-6">
              {items.map((item, index) => (
                <BannerMenuCard key={index} num={item.num} text={item.text} />
              ))}
            </div> */}
            <div className="mt-24 sm:mt-28 md:mt-36 lg:mt-32 xl:mt-36 flex flex-wrap justify-center gap-4 md:gap-10 xl:gap-6 ">
              {items.map((item, index) => (
                <div
                  key={index}
                  className="flex-basis-1/2 sm:flex-basis-1/2 lg:flex-basis-1/4 xl:flex-basis-1/4"
                >
                  <BannerMenuCard num={item.num} text={item.text} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="bg-white bg-[url('./assets/images/bg_image_all.png')] bg-cover">
        <div className="relative h-auto  py-16 px-6 sm:px-28 md:px-40 lg:px-48 md:py-32 mb-10 ">
          <div className="circle size-12 bg-primary absolute top-4 right-2 md:top-8 md:right-4 "></div>
          <div className="circle size-32 bg-primary absolute top-10 right-3 md:top-20 md:right-6 md:block hidden"></div>

          <h2 className="text-xl sm:text-2xl md:text-3xl font-base text-center">
            WELCOME TO A NEW CLOSING EXPERIENCE <br /> WITH{" "}
            <span className="font-bold">
              <span className="text-primary">TOPLINE</span> NATIONAL TITLE
            </span>
          </h2>

          <p className="mt-6 sm:mt-8 md:mt-12 text-sm sm:text-base md:text-lg text-center px-4 md:px-0">
            At Top Line National Title, we believe in empowering our consumers
            by providing top-notch title services that ensure seamless real
            estate transactions. Our mission is to offer a transparent,
            efficient, and consumer-focused experience that simplifies the
            complexities of the title process. Our commitment to excellence and
            customer satisfaction sets us apart as a trusted partner in the real
            estate journey.
          </p>

          <p className="mt-4 text-sm sm:text-base md:text-lg text-center px-4 md:px-0">
            What makes us unique is our profound knowledge of the latest
            advancements in technology, particularly in the title and mortgage
            industries. We utilize innovative, AI-driven solutions to deliver
            faster, more accurate, and secure title services. This allows us to
            streamline the process, making it easier and more reliable for our
            clients.
          </p>

          <p className="mt-4 text-sm sm:text-base md:text-lg text-center px-4 md:px-0">
            As we expand nationwide, our core values of integrity, transparency,
            and customer empowerment will remain at the forefront of everything
            we do. Whether you are buying, selling, or refinancing, Top Line
            National Title is here to provide you with the peace of mind that
            comes from knowing your transaction is in capable hands. Join us and
            experience the future of title services today.
          </p>
        </div>

        <div>
          <GetStarted />
          </div>
          </div>
        </div>
      
    </>
  );
};

export default Homepage;

// {/* <div className="h-screen w-full border border-red-600">
//             {/* <BannerMenuCard/> */}

//             <img src="/images/homepage/homeBanner.png" alt="" className="fixed" />

// </div> */}
