import React from 'react'

const OurServiceCard = ({title,desc}) => {
  return (
    <div>
         <div className="mb-4 bg-primary group transition-all duration-500 hover:bg-white rounded-3xl p-5 text-center w-full h-full border border-primary">
              <h2 className="text-white text-[1.25rem] max-sm:text-lg font-semibold pb-1 group-hover:text-primary transition-transform duration-500 group-hover:scale-105 group-hover:tracking-normal">
                {title}
              </h2>
              <span className="block border-b border-white opacity-50 h-1 mx-auto w-full transition-all duration-300 group-hover:border-primary"></span>
              <p className="text-white pt-2 text-[1.1rem] max-sm:text-base group-hover:text-[#A1A1A1] transition-all duration-500">
                {desc}
              </p>
            </div>
    </div>
  )
}
export default OurServiceCard