import React, { useState } from "react";
import TitleCalculator from "./TitleCalculator";
import MortgageCalculator from "./MortgageCalculator";
import BuyerCostEstimator from "./BuyerCostEstimator";
import SellerCostEstimator from "./SellerCostEstimator";
import { Link } from "react-router-dom";

const TitleCostEstimatorComponent = () => {
  const [activeSection, setActiveSection] = useState(0);

  const sections = [
    {
      id: 0,
      title: "TITLE CALCULATOR",
      content: <TitleCalculator />,
      desc: "If you don’t see what you’re looking for please email one of our staff members.",
    },
    {
      id: 1,
      title: "MORTGAGE CALCULATOR",
      content: <MortgageCalculator />,
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
    },
    {
      id: 2,
      title: "BUYER COST ESTIMATOR",
      content: <BuyerCostEstimator />,
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
    },
    {
      id: 3,
      title: "SELLER COST ESTIMATOR",
      content: <SellerCostEstimator />,
      desc: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy",
    },
  ];

  return (
    <>
      <div className="mx-24 mb-4">
        {/* Title and Description based on active section */}
        <h2 className="text-3xl font-extrabold text-center py-10">
          <span className="text-black">{sections[activeSection].title.split(' ')[0]} </span>
          <span className="text-primary">{sections[activeSection].title.split(' ').slice(1).join(' ')}</span>
        </h2>
        <p className="mt-2 text-gray-600 text-center text-[1.625rem]">
          {sections[activeSection].desc}
        </p>
      </div>

      <div className="flex max-md:flex-col mx-5 lg:mx-24">
        <div className="max-md:grid lg:w-2/5 p-4 pr-8 gap-4 md:space-y-5">
          {sections.map((section, index) => (
            <div
              key={section.id}
              className={`p-4 border-2 border-primary rounded-full cursor-pointer  text-center font-semibold shadow-lg ${
                activeSection === index
                  ? "bg-primary text-white"
                  : "bg-white text-primary"
              }`}
              onClick={() => setActiveSection(index)}
            >
              {section.title}
            </div>
          ))}
        </div>

        <div className="lg:w-3/5 w-full">
          <div className="w-auto p-4 m-4 border bg-white border-primary shadow-xl shadow-[#00000029] rounded-xl">
            {sections[activeSection].content}
          </div>

          <div className="w-auto pl-6 pr-3 py-2 m-4 shadow-xl shadow-[#00000029] rounded-xl bg-primary text-white flex justify-between">
            <div className="text-xl md:text-2xl w-fit p-1">
              GET PERSONALISED <span className="font-semibold">QUOTE</span>
            </div>
            <div className="border group flex bg-white text-primary justify-center items-center font-semibold  hover:font-bold rounded-lg px-12 py-2">
            <Link
              to="/contact"
              
            >
              <button className="transition-transform transform group-hover:scale-110 text-sm md:text-base">
                TALK TO US
              </button>
            </Link>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TitleCostEstimatorComponent;
