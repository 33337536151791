import React from "react";
import StateDropdown from "../state-dropdown/StateDropdown";

const MortgageCalculator = () => {
  return (
    <div className="*:mt-5 mx-2">
      <div className="flex items-center">
        <h2 className="text-xl font-bold">MORTGAGE CALCULATOR</h2>
        <StateDropdown/>
        
      </div>
      
      <div className="text-[1.125rem] *:mt-3"> 
        <div className="flex items-center">
          <p>Price of Home</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
        <div className="flex items-center">
          <p>Down Payment</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
        <div className="flex items-center">
          <p>Mortgage Term</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
        <div className="flex items-center">
          <p>Interest Rate</p>
          <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>
      </div>
      <span className="bg-primary w-full h-[1px] inline-block"></span>

      <div className="flex text-[1.125rem] mx-2 items-center">
          <p className="font-normal">Over $10 Million - $2.00 per $1000</p>
          <input placeholder="$ 100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
        </div>

      
    </div>
  );
};

export default MortgageCalculator;
