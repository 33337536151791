import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReduxProvider } from './providers/ReduxProvider';

const injectUserWayScript = () => {
  const script = document.createElement('script');
  script.setAttribute('data-account', 'sukLXB6xXR'); // Your data account
  script.setAttribute('src', 'https://cdn.userway.org/widget.js');
  script.async = true;
  document.body.appendChild(script);
};

const RootComponent = () => {
  useEffect(() => {
    injectUserWayScript(); // Inject the UserWay script on component mount
  }, []); // The empty dependency array ensures it runs only once

  return (
    <React.StrictMode>
      <ReduxProvider>
        <App />
      </ReduxProvider>
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();