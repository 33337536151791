import React from "react";
import { EMail, Linkedin, Twitter } from "./MySvgComponents/MySvgComponents";
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="w-full h-full bg-black relative overflow-hidden bg-center bg-cover bg-[url('./assets/images/footer/footer_bg.png')]">
      <div className="lg:flex lg:items-center lg:justify-center">
        <div className="lg:grid grid-rows-1">
          <div className="lg:grid grid-cols-12">
            <div className="col-span-3 flex items-start lg:pt-10 lg:pr-2 max-lg:ml-5">
            <img
                src="/images/footer/footer_logo.png"
                alt="topline_logo"
                className="xl:w-80 lg:w-72 md:w-72 sm:w-60 w-52 h-auto max-lg:my-10"
              />
            </div>
            <div className="col-span-9 lg:pl-32 relative mt-4 lg:mt-8 mb-12 ml-5">
              <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-2 gap-0">
                <div>
                  <ul className="lg:pt-2 space-y-2 max-lg:ml-2">
                    <li>
                      <Link
                      to="/"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200 font-semibold"
                      >
                        HOME
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/about"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200 font-semibold"
                      >
                        ABOUT US
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                      to="/title_cost_estimator"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200"
                      >
                        TITLE COST ESTIMATOR
                      </Link>
                    </li> */}
                    <li>
                      <Link
                      to="/resources"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200 font-semibold"
                      >
                        RESOURCES
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/contact"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200 font-semibold"
                      >
                        CONTACT US
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-bold text-white lg:text-[16px] md:text-[16px] text-[16px] pt-4 lg:pt-2 max-lg:ml-2">
                    Our Services
                  </p>
                  <ul className="mt-2 lg:mt-2 space-y-2 max-lg:ml-2">
                    <li>
                      <Link
                      to="/services/our_offerings"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200 font-semibold"
                      >
                        OUR OFFERINGS
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/services/our_offerings#our-services"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200"
                      >
                        Title Search & Examination
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/services/our_offerings#our-services"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200"
                      >
                        Settlement Services
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/services/our_offerings#our-services"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200"
                      >
                        Title Report Indexing & Data Extraction
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/services/our_offerings#our-services"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200"
                      >
                        Faster Turnaround Time
                      </Link>
                    </li>
                    <li>
                      <Link
                      to="/services/request_a_quote"
                        className="text-white hover:text-primary lg:text-[12px] md:text-[14px] text-[12px] transition-all duration-200 font-semibold"
                      >
                        REQUEST A QUOTE
                      </Link>
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="font-semibold text-white lg:text-[17px] md:text-[12px] text-[16px] pt-4 lg:pt-2 max-lg:ml-2">
                    Follow us
                  </p>
                  <div className="flex space-x-0 mt-2 -ml-2 max-lg:ml-0">
                    {/* <div className="group" href="/#" target="_blank" rel="noopener noreferrer">
                      <Facebook/>
                    </div> */}
                    <a href="mailto:info@toplinenational.com">
                    <div className="group" href="/#" target="_blank" rel="noopener noreferrer">
                      <EMail className="fill-white hover:fill-primary hover:scale-110 size-12" />
                    </div>
                    </a>
                    <a href="https://www.linkedin.com/in/topline-national-title-4a63a8327/">
                    <div className="group" href="/#" target="_blank" rel="noopener noreferrer">
                      <Linkedin className="fill-white hover:fill-primary hover:scale-110 size-12" />
                    </div>
                    </a>
                    <a href="https://x.com/toplinenational">
                    <div className="group" href="/#" target="_blank" rel="noopener noreferrer">
                      <Twitter className="fill-white hover:fill-primary hover:scale-110 size-12 transition-transform duration-300 hover:shadow-[0_0_30px_0_rgba(255,255,255,0.6)] hover:rounded-full" />
                    </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start lg:mt-7 mt-0">
          <div className="">
          <div className="flex flex-col sm:flex-row sm:space-x-9 mt-2 max-lg:ml-5">
              <Link
                to="/privacy-policy"
                className="text-white text-xs font-light hover:text-primary transition-all duration-200 mb-2"
              >
                Terms & Conditions
              </Link>
              <Link
                 to="/privacy-policy"
                className="text-white text-xs font-light hover:text-primary transition-all duration-200 mb-2 lg:pl-2"
              >
                Privacy Policy
              </Link>
              <Link
                 to="/privacy-policy"
                className="text-white text-xs font-light hover:text-primary transition-all duration-200 mb-2 lg:pl-2"
              >
                Cookies Policy
              </Link>
              <Link
                 to="/privacy-policy"
                className="text-white text-xs font-light hover:text-primary transition-all duration-200 mb-2 lg:pl-2"
              >
                © Copyright
              </Link>
              <Link
                 to="/privacy-policy"
                className="text-white text-xs font-light hover:text-primary transition-all duration-200 mb-2 lg:pl-2"
              >
                Policies & Procedures
              </Link>
            </div>
          </div>
          </div>
          <div className="w-full border border-white border-opacity-60 mt-2">
            {" "}
          </div>
          <div className="text-left text-white py-2 mb-2 text-[10px] transition-all duration-500 hover:text-primary hover:font-semibold hover:tracking-normal max-lg:ml-5 w-fit">
            © {currentYear} TOP LINE NATIONAL TITLE, All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
