import React from "react";
// import { MachineLearning, Medal, Practice, Time } from "../MySvgComponents/MySvgComponents";

const DifferentiatorMenuCard = ({icon, title}) => {
  return (
    <>
      <div>

      <div className="w-fit m-3 rounded-lg border-2 border-primary group relative overflow-hidden hover:shadow-xl transition-all duration-300">
        <div className="relative inline-block group max-w-[300px] w-[300px] h-[120px] bg-white">
        
          <span className="relative z-10 group-hover:bg-transparent transition-all duration-300 ease-in-out flex flex-col items-center mt-6 text-xl group-hover:text-2xl  group-hover:font-semibold group-hover:text-white text-primary group-hover:scale-110">
            {icon}
          </span>

          <div className="z-0 border-none absolute top-0 left-0 right-0 h-0 bg-primary group-hover:h-full group-hover:w-full group-hover:border-primary transition-all duration-300 ease-in-out group-hover:shadow-lg shadow-black"></div>
        </div>

        <div className="max-w-[300px] w-full h-12 flex items-center justify-center text-center font-semibold bg-white group-hover:font-bold group-hover:text-primary transition-all duration-300">{title}</div>
      </div>
      </div>
    </>
  );
};

export default DifferentiatorMenuCard;
