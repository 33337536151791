import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

const GetStarted = () => {
  const [submissionStatus, setSubmissionStatus] = useState(""); // State to keep track of the submission status
  const [loading, setLoading] = useState(false); // State for managing loading indicator

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true); // Start loading indicator
    try {
      // const response = await axios.post(`${process.env.REACT_APP_HOST}/get-started`, data);
      const response = await axios.post(
        `http://135.148.55.73:3001/get-started`,
        data
      );
      console.log("resoonse", response);
      if (response.status === 200) {
        setSubmissionStatus("Message sent successfully!"); // Success message
        reset(); // Reset the form fields
      } else {
        setSubmissionStatus("Failed to send message. Please try again."); // Error message
      }
    } catch (error) {
      setSubmissionStatus("There was an error submitting the form."); // Error message
      console.error("There was an error submitting the form:", error);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  return (
    <div className="flex justify-center items-center py-20 pb-24 md:pb-40 lg:pb-10 p-10 md:pl-16 lg:pl-28 pr-16 lg:pr-16 h-fit">
      <div className="flex flex-col max-w-[1000px] w-full">
        <div className="text-[26px] md:text-[28px] lg:text-[2rem]">
          Ready to
          <span className="font-extrabold text-primary"> Get Started</span>
          <span className="font-extrabold">?</span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-[353px]">
          <div className="mt-5 max-lg:flex-col md:flex lg:flex lg:gap-5">
            <div className="flex flex-col max-lg:mb-5 max-lg:mt-5 w-full">
              <input
                placeholder="Name"
                type="text"
                id="name"
                maxLength={30}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""))
                }
                {...register("name", {
                  required: "Name is required",
                  maxLength: {
                    value: 30,
                    message: "Name Cannot Be Longer Than 30 Characters",
                  },
                })}
                className={`text-[14px] md:text-lg w-full h-[62px] text-[#A1A1A1] border border-[#A1A1A1] px-5 rounded-md font-medium ${
                  errors.name ? "border-red-500" : "border-[#A1A1A1]"
                }`}
              />
              {errors.name && (
                <div className="text-red-500 text-sm mt-1">
                  {errors.name.message}
                </div>
              )}
            </div>

            <div className="flex flex-col max-lg:mb-5 max-lg:mt-5 w-full">
              <input
                placeholder="Email"
                type="email"
                id="email"
                maxLength={45}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[^@]+@[^@]+\.[^@]+$/,
                    message: "Invalid Email Address",
                  },
                  maxLength: {
                    value: 45,
                    message: "Email Address Must Be at Most 30 Characters Long",
                  },
                })}
                className={`text-[14px] md:text-lg w-full h-[62px] text-[#A1A1A1] border border-[#A1A1A1] px-5 rounded-md font-medium ${
                  errors.email ? "border-red-500" : "border-[#A1A1A1]"
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>
          <div className="my-5 flex flex-col">
            <textarea
              placeholder="Message"
              id="message"
              {...register("message", { required: "Message is required" })}
              className={`text-[14px] md:text-lg w-full h-[114px] text-[#A1A1A1] border border-[#A1A1A1] px-5 py-3 rounded-md font-medium ${
                errors.message ? "border-red-500" : "border-[#A1A1A1]"
              }`}
            />
            {errors.message && (
              <p className="text-red-500 text-sm mt-1">
                {errors.message.message}
              </p>
            )}
          </div>

          <button
            type="submit"
            className="px-14 py-2 mt-4 md:mt-2 bg-white text-[#A1A1A1] hover:text-white text-base font-semibold rounded-full hover:bg-primary transition duration-300 border border-[#A1A1A1] hover:border-primary hover:shadow-md"
            disabled={loading} // Disable button when loading
          >
            {loading ? (
              <div className="flex justify-center items-center space-x-2">
                <span className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-primary"></span>
                <span>Submitting...</span>
              </div>
            ) : (
              "SUBMIT"
            )}
          </button>

          {/* Display the submission status */}
          {submissionStatus && (
            <div className="mt-4 text-[14px] md:text-lg text-center">
              {submissionStatus.includes("successfully") ? (
                <p className="text-green-500">{submissionStatus}</p>
              ) : (
                <p className="text-red-500">{submissionStatus}</p>
              )}
            </div>
          )}
        </form>
      </div>

      <div className="max-w-[492px] h-auto mx-5 flex justify-end w-full max-sm:hidden">
        <span className="absolute w-14 h-14 md:w-32 md:h-32 lg:w-36 lg:h-36 bg-primary rounded-full z-0 -mt-4 -mr-4 md:-mt-8 md:-mr-8 lg:-mt-10 lg:-mr-10"></span>
        <img
          src="/images/contact-us/get_started.png"
          alt="GetStartedImage"
          className="z-10"
        />
      </div>
    </div>
  );
};

export default GetStarted;
