import React from "react";
import StateDropdown from "../state-dropdown/StateDropdown";

const BuyerCostEstimator = () => {
  return(
    <div className="*:mt-5">
    <div className="flex items-center mx-2">
      <h2 className="text-xl font-bold">BUYER COST ESTIMATOR: PURCHASING REAL ESTATE</h2>
      <StateDropdown/>
    </div>
    <div className="flex text-[1.125rem] mx-2 items-center">
        <p className="font-normal text-[#3A3A3A]">Loan Amount</p>
        <input placeholder="100000" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
    </div>
    
    <div className="bg-[#EFEFEF] text-[#3A3A3A] py-2 p-2 rounded-lg text-[1.125rem] *:mt-3"> 
      <div className="flex items-center">
        <p>Settlement Fee</p>
        <input placeholder="$730" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
      <div className="flex items-center">
        <p>Loan Policy Title Premium (Simultaneous Issue Rate)</p>
        <input placeholder="$25 + any endorse..." type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
      <div className="flex items-center">
        <p>Survey and Elevation certificate *if needed*</p>
        <input placeholder="$300 - $500" type="number" className="ml-auto p-2 border rounded-lg max-w-[100px] md:max-w-[200px]"/>
      </div>
    </div>
      
      <p className="max-w-[414px] text-sm mx-2  text-[#3A3A3A]">*Title endorsements subject to lender’s requirement. Recording fees and taxes subject to state rates. Call for a quote.</p>
      
    </div>
  )
};

export default BuyerCostEstimator;
