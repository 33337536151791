import React from "react";
import { Testimonials } from "../../swiperComponents/MySwipers";
import GetStarted from "../../components/GetStarted";

const AboutUs = () => {
  //Patnering Companies
  const partners = [
    {
      image: "images/about-us/partners-logos/earnnest-wordmark.png",
      alt: "Earnest Logo",
    },
    {
      image: "images/about-us/partners-logos/norarize.svg",
      alt: "Notarize Logo",
    },
    {
      image: "images/about-us/partners-logos/qualia.svg",
      alt: "Qualia Logo",
    },
    {
      image: "images/about-us/partners-logos/certifid.svg",
      alt: "Certifid Logo",
    },
    {
      image: "images/about-us/partners-logos/we_run_on_eos.png",
      alt: "We Run On EOS Logo",
    },
    {
      image: "images/about-us/partners-logos/secure_insight.png",
      alt: "Secure Insight Logo",
    },
    {
      image: "images/about-us/partners-logos/ctm_econtracts.png",
      alt: "Ctm eContracts Logo",
    },
    {
      image: "images/about-us/partners-logos/encompass.png",
      alt: "Encompass Logo",
    },
    {
      image: "images/about-us/partners-logos/catic_active_approved.png",
      alt: "Catic Logo",
    },
    {
      image: "images/about-us/partners-logos/stellar_logo.png",
      alt: "Stellar Logo",
    },
  ];
  // Board Member
  const ourBoard = [
    {
      background_image: "bg-[url('./assets/images/about-us/brian_hayes.png')]",
      name: "Brian Hayes",
      designation: "Chief Operating Officer",
      paragraphs: [
        `Brian Hayes serves as the Chief Operating Officer for Top Line National Title. In his capacity, Mr. Hayes is entrusted with the 
                oversight of all corporate operations, encompassing business development, and is instrumental in securing the requisite national agreements. 
                With a breadth of experience spanning 20 years in the title and mortgage industry, Mr. Hayes possesses a deep-seated understanding of the sector's 
                nuances and dynamics.`,

        `The adage, “How you do anything is how you do everything,” encapsulates Mr. Hayes's philosophy. This sentiment suggests that 
                 the manner in which we conduct ourselves and execute tasks in various aspects of life are closely intertwined, a concept that remains largely overlooked by many.`,
      ],
      social_media_links: {
        linkedin_link: "https://www.linkedin.com/in/brian-hayes-189b1451/",
      },
    },
    {
      background_image:
        "bg-[url('./assets/images/about-us/suzanne_barner.png')]",
      name: "Suzanne Barner",
      designation: "General Counsel/Licensed Title Agent",
      paragraphs: [
        `Suzanne began her career in sales and marketing, where she developed strong skills in client relations and strategic planning. 
                After attending Georgia State University College of Law, she transitioned to the Real Estate and Title Industry, where she has since built a robust 
                career. Recently she joined a leading IT/AI start-up in the title, mortgage, and real estate industries as General Counsel, overseeing legal compliance 
                and risk management. Her proactive approach to dispute resolution and ability to navigate complex legal landscapes have earned her a reputation as a 
                trusted advisor to executive and operational teams.`,

        `Suzanne has successfully guided numerous companies and individuals through intricate real estate transactions, ensuring compliance
                and fostering smooth operations. Her dedication to client satisfaction and her collaborative approach have made her an invaluable asset to the 
                companies she has served.`,
      ],
      social_media_links: {
        linkedin_link: "https://www.linkedin.com/in/suzannebarner/",
      },
    },
  ];

  return (
    <div className="relative overflow-hidden">
      {/* About Us Banner */}
      <div className=" bg-[url('./assets/images/about-us/about_us_banner.png')] overflow-hidden bg-no-repeat bg-top  bg-fixed  w-full flex justify-center items-center h-[496px] -z-10 ">
        <div className="text-5xl font-extrabold text-white text-center ">
          ABOUT <span className="text-primary"> US</span>{" "}
        </div>
      </div>

      <div className="bg-[url('./assets/images/background-images/background.png')]  bg-no-repeat bg-center">
        {/* Paragraph block*/}
        <div className="relative flex flex-col justify-center items-center py-20 overflow-hidden">
          <div className="absolute border border-red-600"></div>
          <div className="circle size-3 bg-primary absolute top-10 ml-12 left-1/4"></div>
          <div className="text-center text-secondary text-[26px] md:text-[28px] lg:text-[2rem] max-w-[720px] p-5 mx-10 leading-[43px]">
            WELCOME TO A NEW CLOSING EXPERIENCE WITH{" "}
            <span className="font-extrabold">
              <span className="text-primary"> TOPLINE </span> NATIONAL TITLE{" "}
            </span>
            <span className="circle size-8 bg-primary absolute ml-36 lg:-mt-7 lg:ml-28"></span>
          </div>

          <div className="text-center text-secondary text-sm md:text-base lg:text-lg max-w-[1175px] mx-10 z-10">
            <p className="mt-5">
              At Top Line National Title, we believe in empowering our consumers
              by providing top-notch title services that ensure seamless real
              estate transactions. Our mission is to offer a transparent,
              efficient, and consumer-focused experience that simplifies the
              complexities of the title process. Our commitment to excellence
              and customer satisfaction sets us apart as a trusted partner in
              the real estate journey.
            </p>
            <p className="mt-5">
              What makes us unique is our profound knowledge of the latest
              advancements in technology, particularly in the title and mortgage
              industries. We utilize innovative, AI-driven solutions to deliver
              faster, more accurate, and secure title services. This allows us
              to streamline the process, making it easier and more reliable for
              our clients.
            </p>
            <p className="mt-5">
              As we expand nationwide, our core values of integrity,
              transparency, and customer empowerment will remain at the
              forefront of everything we do. Whether you are buying, selling, or
              just need Property Information Reports. Include a link to an
              eCommerce site where clients can order one off or bulk search
              reports that go directly to Stellar to complete with a TopLine
              National Title template to return to request. Stellar bills
              TopLine for searches and Top Line bills client thru eCommerce
              site., Top Line National Title is here to provide you with the
              peace of mind that comes from knowing your transaction is in
              capable hands. Join us and experience the future of title services
              today.
            </p>
          </div>
          <span className="circle size-20 bg-primary absolute bottom-8 left-12 z-0"></span>
        </div>

        {/*MEET OUR BOARD*/}
        <div className="bg-[#FFF2F2] h-fit px-10 pb-20">
          <div className="relative py-10 flex-col justify-center items-center">
            <div className="circle size-8 bg-primary absolute top-12 -right-2 max-md:hidden"></div>
            <div className="circle size-20 bg-primary absolute top-20 right-0 max-md:hidden"></div>
            <div className="text-[26px] md:text-[28px] lg:text-[2rem] text-center">
              MEET <span className="text-primary font-bold">OUR BOARD </span>{" "}
            </div>
            <div className="text-[20px] md:text-[22px] lg:text-[1.625rem] text-secondary text-center py-4 px-5 md:px-24">
              From "Meet Our Board" with greetings! The esteemed leaders on our
              board each provide a special area of expertise and a passion for
              our purpose that unites them. Their combined expertise and
              commitment are invaluable in directing our company and advancing
              our mission.
            </div>
          </div>
          {/* Board Members */}
          <div className="flex flex-wrap justify-center gap-12 md:gap-24">
            {ourBoard?.map(
              (
                {
                  background_image,
                  name,
                  paragraphs,
                  designation,
                  social_media_links,
                },
                index
              ) => (
                <div
                  key={index}
                  className={`${background_image} bg-cover group bg-center h-[598px] w-[540px] flex items-end rounded-xl px-8 py-3 hover:p-3 transition-all duration-100`}
                >
                  <div className="backdrop-blur-lg rounded-lg p-5 group-hover:px-8 bg-white bg-opacity-65 border-[3px] w-full flex flex-col justify-between group-hover:h-full">
                    <div>
                      <div className="text-center text-lg md:text-xl lg:text-[2rem] text-secondary font-bold group-hover:text-primary mt-2">
                        {name}
                      </div>

                      <div className="text-center text-xs lg:text-[1rem] text-secondary font-medium mt-6">
                        {designation}
                      </div>
                    </div>

                    <div className="max-h-0 overflow-hidden transition-[max-height] duration-200 ease-in-out group-hover:max-h-[400px] text-secondary text-xs lg:text-[1rem] leading-[20px]">
                      {paragraphs?.map((para, index) => (
                        <p key={index}>{para}</p>
                      ))}
                    </div>

                    <div className="flex justify-center mt-3 space-x-1">
                      <a
                        href={social_media_links?.linkedin_link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="images\social-icons\linkedIn.svg"
                          alt="LinkedIn Logo"
                          className="size-6 md:size-8 lg:size-12 group-hover:mb-3 transition-all duration-300"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>

        {/* OUR TRUSTED PARTNERS/TECH */}
        <div className="px-8 md:px-24 py-20 flex flex-col items-center">
          <div className="text-center text-[26px] md:text-[28px] lg:text-[2rem] text-secondary mt-14 p-10 ">
            OUR TRUSTED{" "}
            <span className="text-primary font-bold"> PARTNERS/TECH</span>{" "}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 shadow-inner shadow-[#00000029] justify-center border-2 border-primary rounded-xl p-14 w-fit gap-20 mb-20 py-16">
            {partners.map((partner, index) => (
              <div
                key={index}
                className="grayscale hover:grayscale-0 hover:scale-110 flex justify-center items-center transition-all duration-300 ease-linear"
              >
                <img
                  src={partner.image}
                  alt={partner.alt}
                  className="w-full h-auto"
                />
              </div>
            ))}
          </div>
        </div>

        {/* TESTIMONIALS */}
        <div className="py-52 flex items-center">
          <div className="absolute left-1/4 -mt-96 -ml-52 z-10">
            <img
              src="images\about-us\semicolon.svg"
              alt="Semicolon"
              className=" w-[350px] rotate-180 scale-x-[-1]"
            />
          </div>
          <span className="bg-[#EBEBEB] w-[954px] h-[769px] -mt-32 rounded-l-2xl absolute inline-block right-0 z-0"></span>
          <div className=" max-md:flex-col flex  justify-center items-center  w-full">
            <div className="text-[26px] md:text-[28px] lg:text-[2rem] text-secondary max-md:text-center text-left -mt-20 max-w-[280px] mx-12 leading-[50px] z-30">
              OUR CUSTOMERS{" "}
              <span className="text-primary font-bold">TESTIMONIALS</span>
            </div>
            <div className="xl:max-w-[1040px] max-w-[450px] md:max-w-[500px] z-10 h-full relative p-5">
              <Testimonials />
            </div>
          </div>

          <div className="circle size-44 bg-primary absolute left-0 mt-[700px] -ml-20"></div>
        </div>
        <div>
          <GetStarted />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
