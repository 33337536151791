import './App.css';
import AppRoutes from './routes/AppRoutes';
import React from 'react';

function App() {
  return (
    <>
    <AppRoutes/>   
    </>
  );
}

export default App;
