// src/components/RequestAQuote.jsx

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import GetStarted from "../../components/GetStarted";
import axios from "axios";

const RequestAQuote = () => {
  const [submissionStatus, setSubmissionStatus] = useState(""); // State to keep track of the submission status
  const [loading, setLoading] = useState(false); // State for managing loading indicator

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true); // Start loading indicator
    try {
      const response = await axios.post(
        `http://135.148.55.73:3001/request-quote`,
        data
      );

      if (response.status === 200) {
        setSubmissionStatus("Message sent successfully!"); // Success message
        reset(); // Reset the form fields
      } else {
        setSubmissionStatus("Failed to send message. Please try again."); // Error message
      }
    } catch (error) {
      setSubmissionStatus("There was an error submitting the form."); // Error message
      console.error("There was an error submitting the form:", error);
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="mt-16 bg-[url('./assets/images/services/quote_banner.png')] flex justify-center items-center bg-center bg-no-repeat bg-cover h-[23.125rem]">
        <p className="text-3xl md:text-5xl font-bold text-white text-center">
          REQUEST <span className="text-primary">A QUOTE</span>
        </p>
      </div>

      <div className="px-10 relative bg-[url('./assets/images/background-images/background.png')]">
        <div className="max-md:hidden circle absolute size-20 bg-primary right-4 -top-12"></div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="max-w-7xl mx-auto p-8 bg-white shadow-lg shadow-gray-400 rounded-lg md:space-y-6 mt-10 md:mt-16 mb-16 border border-primary"
        >
          {/* Line 1: Transaction Type */}
          <div>
            <div className="md:flex lg:space-x-6 md:space-x-4">
              <label className="text-lg font-bold ml-3 text-gray-700 mb-2 mt-2">
                Transaction Type*
              </label>
              <div className="flex flex-col md:flex-row md:space-x-16">
                <label className="flex items-center mt-2 md:mt-0">
                  <input
                    type="radio"
                    id="transactionTypeBuying"
                    value="Buying"
                    {...register("transactionType", {
                      required: "Transaction type is required",
                    })} // Register input with validation
                    className="form-checkbox appearance-none h-6 w-6 border border-gray-300 rounded-sm checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className="ml-2 text-[#3A3A3A]">Buying</span>
                </label>
                <label className="flex items-center mt-2 md:mt-0">
                  <input
                    type="radio"
                    id="transactionTypeSelling"
                    value="Selling"
                    {...register("transactionType", {
                      required: "Transaction type is required",
                    })} // Register input with validation
                    className="form-checkbox appearance-none h-6 w-6 border border-gray-300 rounded-sm checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className="ml-2 text-[#3A3A3A]">Selling</span>
                </label>
                <label className="flex items-center mt-2 md:mt-0">
                  <input
                    type="radio"
                    id="transactionTypeRefinancing"
                    value="Refinancing"
                    {...register("transactionType", {
                      required: "Transaction type is required",
                    })} // Register input with validation
                    className="form-checkbox appearance-none h-6 w-6 border border-gray-300 rounded-sm checked:bg-primary checked:border-transparent focus:outline-none"
                  />
                  <span className="ml-2 text-[#3A3A3A]">Refinancing</span>
                </label>
              </div>
              {errors.transactionType && (
                <p className="text-red-500 text-sm mt-3">
                  {errors.transactionType.message}
                </p>
              )}
            </div>
          </div>

          {/* Line 2: Name */}
          <div className="md:flex md:space-x-8 mt">
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                First Name*
              </label>
              <input
                type="text"
                id="firstName"
                placeholder="FIRST NAME"
                maxLength={25}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^A-Za-z]/g, ""))
                }
                {...register("firstName", {
                  required: "First name is required",
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-2 md:mb-0"
              />
              {errors.firstName && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.firstName.message}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                Last Name*
              </label>
              <input
                type="text"
                id="lastName"
                placeholder="LAST NAME"
                maxLength={25}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^A-Za-z]/g, ""))
                }
                {...register("lastName", {
                  required: "Last name is required",
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-2"
              />
              {errors.lastName && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.lastName.message}
                </p>
              )}
            </div>
          </div>

          {/* Line 3: Company & Email */}
          <div className="md:flex md:space-x-8">
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                Company*
              </label>
              <input
                type="text"
                id="company"
                placeholder="COMPANY NAME"
                maxLength={50}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(
                    /[^A-Za-z\s.,&'-]/g,
                    ""
                  ))
                }
                {...register("company", {
                  required: "Company name is required",
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-4 md:mb-0"
              />
              {errors.company && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.company.message}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                Email*
              </label>
              <input
                type="email"
                placeholder="EMAIL"
                maxLength={45}
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
                    message: "Invalid email format",
                  },
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-4 md:mb-0"
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>

          {/* Line 4: Phone & Loan Amount */}
          <div className="md:flex md:space-x-8">
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                Phone*
              </label>
              <input
                type="text" // Changed from 'number' to 'text' to handle leading zeros and better UX
                id="phone"
                placeholder="PHONE NUMBER"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                {...register("phone", {
                  required: "Phone number is required",
                  minLength: {
                    value: 6,
                    message: "Phone number must be at least 6 digits",
                  },
                  maxLength: {
                    value: 15,
                    message: "Phone number can be up to 15 digits",
                  },
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-4 md:mb-0"
              />
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.phone.message}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                Loan Amount*
              </label>
              <input
                type="number" // Changed from 'text' to 'number'
                id="loanAmount"
                placeholder="$ LOAN AMOUNT"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                maxLength={12}
                {...register("loanAmount", {
                  required: "Loan amount is required",
                  min: {
                    value: 100,
                    message: "Loan amount must be at least $100",
                  },
                  max: {
                    value: 999999999999,
                    message: "Loan amount can be up to 12 digits",
                  },
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-4 md:mb-0"
              />
              {errors.loanAmount && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.loanAmount.message}
                </p>
              )}
            </div>
          </div>

          {/* Line 5: Property Address */}
          <div>
            <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
              Property Address*
            </label>
            <input
              type="text"
              id="propertyAddress"
              placeholder="STREET ADDRESS"
              {...register("propertyAddress", {
                required: "Property address is required",
                maxLength: {
                  value: 255,
                  message: "Property address must be less than 255 characters",
                },
              })}
              className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-2 md:mb-0"
            />
            {errors.propertyAddress && (
              <p className="text-red-500 text-sm mt-1 ml-3">
                {errors.propertyAddress.message}
              </p>
            )}
          </div>

          {/* Line 6: Address Line 2 */}
          <div>
            <input
              type="text"
              id="addressLine2"
              placeholder="ADDRESS LINE 2"
              {...register("addressLine2", {
                maxLength: {
                  value: 255,
                  message: "Address line 2 must be less than 255 characters",
                },
              })}
              className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-2 md:mb-0"
            />
            {errors.addressLine2 && (
              <p className="text-red-500 text-sm mt-1 ml-3">
                {errors.addressLine2.message}
              </p>
            )}
          </div>

          {/* Line 7: City, State, Zip Code */}
          <div className="md:flex md:space-x-8">
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                City*
              </label>
              <input
                type="text"
                id="city"
                placeholder="CITY"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^A-Za-z\s]/g, ""))
                }
                maxLength={25}
                {...register("city", {
                  required: "City is required",
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-2 md:mb-0"
              />
              {errors.city && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.city.message}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                State*
              </label>
              <input
                type="text"
                id="state"
                placeholder="STATE"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^A-Za-z]/g, ""))
                }
                maxLength={20}
                {...register("state", {
                  required: "State is required",
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-2 md:mb-0"
              />
              {errors.state && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.state.message}
                </p>
              )}
            </div>
            <div className="flex-1">
              <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
                ZIP CODE*
              </label>
              <input
                type="text"
                id="zipCode"
                placeholder="ZIP CODE"
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                maxLength={7}
                {...register("zipCode", {
                  required: "Zip code is required",
                  minLength: {
                    value: 3,
                    message: "Zip code must be at least 3 digits",
                  },
                })}
                className="w-full p-2 pl-4 border border-gray-300 rounded-md mb-4 md:mb-0"
              />
              {errors.zipCode && (
                <p className="text-red-500 text-sm mt-1 ml-3">
                  {errors.zipCode.message}
                </p>
              )}
            </div>
          </div>

          {/* Line 8: Comments */}
          <div>
            <label className="block text-lg font-bold ml-3 text-gray-700 mb-2">
              Comments
            </label>
            <textarea
              placeholder="COMMENTS"
              id="comments"
              {...register("comments", {
                maxLength: {
                  value: 255,
                  message: "Comments must be less than 255 characters",
                },
              })}
              className="w-full p-2 pl-4 border border-gray-300 rounded-md h-16 mb-4"
            />
            {errors.comments && (
              <p className="text-red-500 text-sm mt-1 ml-3">
                {errors.comments.message}
              </p>
            )}
          </div>

          <div className="w-full border border-t-[#D14A2F]"></div>

          {/* Line 9: Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="px-16 py-4 mt-4 md:mt-2 bg-white text-[#A1A1A1] hover:text-white text-base rounded-full hover:bg-primary transition duration-300 border-2 border-[#A1A1A1] hover:border-primary"
              disabled={loading} // Disable button when loading
            >
              {loading ? (
                <div className="flex justify-center items-center space-x-2">
                  <span className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-primary"></span>
                  <span>Submitting...</span>
                </div>
              ) : (
                "SUBMIT"
              )}
            </button>

            {/* Display the submission status */}
            {submissionStatus && (
              <div className="mt-4 text-[14px] md:text-lg text-center">
                {submissionStatus.includes("successfully") ? (
                  <p className="text-green-500">{submissionStatus}</p>
                ) : (
                  <p className="text-red-500">{submissionStatus}</p>
                )}
              </div>
            )}
          </div>
        </form>
        <div className="max-md:hidden circle absolute size-56 bg-primary -left-32"></div>

        <div>
          <GetStarted />
        </div>
      </div>
    </div>
  );
};

export default RequestAQuote;
