import React from "react";
import {  } from "../MySvgComponents/MySvgComponents";

const ContactCards = ({icon, text, link}) => {
  return (
    <div>
      <div className="p-6 rounded-lg shadow-md border bg-white border-primary group hover:bg-primary transition-all duration-300 hover:shadow-2xl flex items-center lg:space-x-4 space-x-4">
        <div className="lg:max-w-[80px] lg:min-w-[80px] min-w-[40px] max-w-[40px] shrink-0"> {icon} </div>
        {link ? (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#001350] lg:text-[17px] text-xs group-hover:text-white lg:group-hover:text-[18px]"
          >
            {text}
          </a>
        ) : (
          <p className="text-[#001350] lg:text-[17px] text-xs group-hover:text-white lg:group-hover:text-[18px]">
            {text}
          </p>
        )}
      </div>
    </div>
  );
};

export default ContactCards;
